import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { Consultas } from '../pages/consultas/Consultas';

import LoginForm from '../pages/Login'
import DetalleTarjeta from '../pages/tarjetas/detalleTarjeta';
import ListTarjetas from '../pages/tarjetas/ListTarjetas';
import ConsultaOyon from '../pages/munioyon/ConsultaOyon';
import Redireccionar from '../components/Redireccionar';
import TarjetaConstatacion from '../pages/tarjetas/constataciones/TarjetaConstatacion';
import RegisterLicencia from '../pages/admin/comprobante_pago/RegisterLicencia';
import Index from '../pages/admin/comprobante_pago';
import Colaboradores from '../pages/admin/mantenimiento/Colaborador';
import withAuth from './withAuth';
import ManageRoles from '../pages/admin/permisos';
import PrivacyPolicy from '../pages/politicas';
import NotFound from '../pages/NotFound';
import { REACT_APP_DOMAIN } from '../services/config';
import MicrosoftIdentityAssociationFile from '../pages/consultas/microsoft-identity-association';
const MiAula = lazy(() => import('../pages/aula/MiAula'));
const Quiz = lazy(() => import('../pages/admin/quiz'));
const Curso = lazy(() => import('../pages/aula/Curso'));
const Encuesta = lazy(() => import('../pages/encuesta/Encuesta'));
const ListCourse = lazy(() => import('../pages/admin/course/ListCourse'));
const SearhOycDNI = lazy(() => import('../pages/admin/oyccoaching/consulta'));
const Evaluacion = lazy(() => import('../pages/admin/evaluacion'));
const CargaBase = lazy(() => import('../pages/admin/oyccoaching/loadTarjeta'));
const Constatacion = lazy(() => import('../pages/tarjetas/constataciones'));
const ReservaCitasPage = lazy(() => import('../pages/ceval/ReservaCitasPage'));
const AdminReservaCita = lazy(() => import('../pages/ceval/AdminReservaCita'));
const ConfigReservaCita = lazy(() => import('../pages/ceval/ConfigReservaCita'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const EvaluacionMTC = lazy(() => import('../pages/EvaluacionMTC'));

const ProtectedEncuesta = withAuth(Encuesta);
const ProtectedMiAula = withAuth(MiAula);
const ProtectedListCourse = withAuth(ListCourse);
const ProtectedSearhOycDNI = withAuth(SearhOycDNI);
const ProtectedEvaluacion = withAuth(Evaluacion);
const ProtectedQuiz = withAuth(Quiz);
const ProtectedCargaBase = withAuth(CargaBase);
const ProtectedConstatacion = withAuth(Constatacion);
const ProtectedAdminReservaCita = withAuth(AdminReservaCita);
const ProtectedConfigReservaCita = withAuth(ConfigReservaCita);
const ProtectedEvaluacionMTC = withAuth(EvaluacionMTC);
const ProtectedDashboard = withAuth(Dashboard);

const commonRoutes = (
    <>
     <Route path="*" element={<NotFound />} />
     <Route path="/login" element={<LoginForm />} />
     <Route path="/tarjeta" element={<ListTarjetas />} />
     <Route exact path="/constatacion" element={<ProtectedConstatacion />} />
    </>
);

const routesForHostname = {
    [`consultas.${REACT_APP_DOMAIN}`]: (
        <>
            <Route path="/consultas" element={<Consultas />} />
            <Route path=".well-known/microsoft-identity-association.json" element={<MicrosoftIdentityAssociationFile />} />
            <Route path="/" element={<Consultas />} />
            <Route exact path="/politicaPrivacidad" element={<PrivacyPolicy />} />
        </>
    ),
    [`admin.${REACT_APP_DOMAIN}`]:(
        <>
            <Route path="/" element={<ProtectedDashboard />} />
            <Route path="/tarjeta" element={<ListTarjetas />} />
            <Route exact path="/tarjeta/:id" element={<DetalleTarjeta />} />
            <Route exact path="/aula/:slug" element={<Curso />} />
            <Route exact path="/register_licencia" element={<RegisterLicencia />} />
            <Route exact path="/oyon" element={<ConsultaOyon />} />
            <Route exact path="/caja" element={<Index />} />
            <Route exact path="/colaboradores" element={<Colaboradores />} />
            <Route path="/consultas" element={<Consultas />} />
            <Route path="/ManageRoles" element={<ManageRoles />} />
            <Route exact path="/LoadTarjetaOyC" element={<ProtectedCargaBase/>} />
            <Route exact path="/consultasOyC" element={<ProtectedSearhOycDNI/>} />
            <Route path="/evaluacion" element={<ProtectedEvaluacion/>} />
            <Route path="/encuesta" element={<ProtectedEncuesta />} />
            <Route path="/course" element={<ProtectedMiAula />} />
            <Route path="/miaula" element={<ProtectedMiAula />} />
            <Route exact path="/constatacion" element={<ProtectedConstatacion />} />
            <Route exact path="/reservaCita" element={<ProtectedAdminReservaCita />} />
            <Route exact path="/config-reserva" element={<ProtectedConfigReservaCita />} />
            <Route path="/evaluacion-mtc" element={<ProtectedEvaluacionMTC />} />
            <Route exact path="/examenes" element={<ProtectedQuiz />} />
            
        </>
    ),
    [`aulavirtual.${REACT_APP_DOMAIN}`]:(
        <>
            <Route path="/miaula" element={<ProtectedMiAula/>} />
            <Route path="/course" element={<ProtectedListCourse/>} />

            <Route path="/" element={<MiAula />} />
            <Route exact path="/aula/:slug" element={<Curso />} />
        </>
    ),
    'consultascursovial.oyccoaching.com':(<>
        <Route exact path="/consultasOyC" element={<SearhOycDNI />} />
        <Route exact path="/" element={<SearhOycDNI />} />
        </>
    ),
    [`evaluacion.${REACT_APP_DOMAIN}`]:(
        //<Route path="/encuesta" element={<ProtectedEncuesta />} />
        <Route path="/" element={<ProtectedEncuesta />} />
    ),
    [`cursovial.${REACT_APP_DOMAIN}`]:(
        <>
        <Route path="/tarjeta" element={<ListTarjetas />} />
        <Route path="/" element={<ListTarjetas />} />
        <Route exact path="/tarjeta/:id" element={<DetalleTarjeta />} />

        </>
    ),
    'redirect.licenciadeconducirmunioyon.com':(
        <Route path="/oyonredirect" element={<Redireccionar />} />
    ),
    [`constataciones.${REACT_APP_DOMAIN}`]:(
          <Route exact path="/tarjeta/:id" element={<TarjetaConstatacion />} />

    ),
    'licenciadeconducirmunioyon.com':(<>
        <Route path="/" element={<ConsultaOyon />} />
        <Route path="/oyon" element={<ConsultaOyon />} />
        </>
    ),
    [`ceval.${REACT_APP_DOMAIN}`]:(<>
        <Route path="/citas" element={<ReservaCitasPage />} />
        </>
    )
};

export { commonRoutes, routesForHostname };
